<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    ref="wrap"
    class="flow-root max-h-80 overflow-auto"
    @scroll="scroll()">
    <ul ref="list">
      <li
        v-if="allNotifications.length == 0"
        class="cursor-pointer px-4">
        <div class="relative flex space-x-3">
          <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
            <div>
              <p class="confirm-delete-text-v2">
                {{ $t("notifications.no_notifications") }}
              </p>
            </div>
          </div>
        </div>
      </li>
      <li
        v-for="note in allNotifications"
        :key="note.id"
        class="cursor-pointer px-4 hover:bg-gray-100"
        @click="readNotif(note)">
        <router-link
          :title="$t('notifications.open')"
          :to="'/' + orderURL(note.notification_type, note.context)"
          @click.prevent>
          <div class="relative pb-8">
            <div class="relative flex space-x-3">
              <div class="flex items-center">
                <Icon
                  :icon-name="note.iconData.iconName"
                  :class-attr="note.iconData.iconClass" />
              </div>
              <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                <div>
                  <p class="confirm-delete-text-v2">
                    <span
                      v-if="![10, 0].includes(note.notification_type)"
                      class="font-medium text-gray-900"
                      >{{ note.context.order_id }}
                    </span>
                    <span
                      v-else
                      class="font-medium text-gray-900"
                      >{{ $t("notifications.system_message") }}</span
                    >
                  </p>
                  <p
                    v-if="[1, 4, 7].includes(note.notification_type)"
                    class="confirm-delete-text-v2">
                    {{ $t("notifications.new_order") }}
                  </p>
                  <div
                    v-if="[30].includes(note.notification_type)"
                    class="confirm-delete-text-v2">
                    {{ $t("notifications.new_issue") }}
                    <p
                      v-html="
                        $t('notifications.new_issue_details', [note.context.title, note.context.creator_org])
                      "></p>
                  </div>
                  <p
                    v-if="[2, 5, 8, 13, 14, 15, 16, 17, 18].includes(note.notification_type)"
                    class="confirm-delete-text-v2">
                    {{ $t("notifications.status_change") }}
                    <br />
                    <span class="font-bold">{{ statusName(note.context.new_status) }}</span>
                  </p>
                  <div
                    v-if="[32, 33].includes(note.notification_type)"
                    class="confirm-delete-text-v2">
                    {{ $t("notifications.issue_status_change") }}
                    <br />
                    <p
                      v-html="
                        $t('notifications.issue_status_change_details', [
                          note.context.title,
                          statusIssueName(note.context.new_status),
                        ])
                      "></p>
                  </div>
                  <div
                    v-if="[31].includes(note.notification_type)"
                    class="confirm-delete-text-v2">
                    {{ $t("notifications.added_comment") }}
                    <p
                      v-html="
                        $t('notifications.added_comment_in_issue', [note.context.title, note.context.posting_org])
                      "></p>
                  </div>
                  <p
                    v-if="[3, 6, 9].includes(note.notification_type)"
                    class="confirm-delete-text-v2"
                    v-html="$t('notifications.order_completed')" />
                  <p
                    v-if="[0, 10].includes(note.notification_type)"
                    class="confirm-delete-text-v2">
                    <span
                      class="font-normal"
                      v-html="note.context.data" />
                  </p>
                </div>
                <div class="text-right text-sm whitespace-nowrap text-gray-500">
                  <time>{{ createdDate(note.created) }}</time>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
  <div
    v-if="allNotifications.length > 0"
    class="content-center w-full align-middle inline-block px-5">
    <ClickableButton
      icon-name="action.show"
      :click-event="
        function () {
          markAllAsRead();
        }
      "
      :title-attr="$t('notifications.mark_all_as_read')"
      :title-text="$t('notifications.mark_all_as_read')"
      color-outline="blue"
      class-attr="w-full inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150" />
  </div>
</template>

<script>
import dayjs from "dayjs";
import { uspAPI } from "../api/axios-base";
import { statusName, statusIssueName } from "../modules/status_utils";
import ClickableButton from "./buttons/ClickableButton.vue";
import Icon from "./icons/Icon.vue";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(LocalizedFormat);

export default {
  name: "NotificationFeed",
  components: {
    ClickableButton,
    Icon,
  },
  props: { allNotifications: { type: Array, default: new Array() }, nextPage: { type: Number, default: 1 } },
  computed: {},
  mounted() {},
  methods: {
    orderURL(id, pk) {
      const idNotification = pk.order_pk || pk.issue_pk;
      const dataRouting = {
        0: { base: "#none", target: "#none" },
        1: { base: "orders", target: "verify" },
        2: { base: "orders", target: "show" },
        3: { base: "orders", target: "show" },
        4: { base: "nplateorders", target: "verify" },
        5: { base: "nplateorders", target: "show" },
        6: { base: "nplateorders", target: "show" },
        7: { base: "scrap-orders", target: "verify" },
        8: { base: "scrap-orders", target: "show" },
        9: { base: "scrap-orders", target: "show" },
        13: { base: "nplateorders", target: "edit" },
        14: { base: "nplateorders", target: "verify" },
        15: { base: "orders", target: "edit" },
        16: { base: "orders", target: "verify" },
        17: { base: "scrap-orders", target: "edit" },
        18: { base: "scrap-orders", target: "verify" },
        30: { base: "issue-comment", target: "show" },
        31: { base: "issue-comment", target: "show" },
        32: { base: "issue-comment", target: "show" },
        33: { base: "issue-comment", target: "show" },
      };
      if (id >= 30)
        return id in dataRouting ? `${dataRouting[id].base}/${dataRouting[id].target}/${idNotification}` : "#none";
      return id in dataRouting ? dataRouting[id].base + "/" + idNotification + "/" + dataRouting[id].target : "#none";
    },
    orderRoute(id) {
      const dataRouting = {
        1: "OrderVerify",
        2: "OrderShow",
        3: "OrderShow",
        4: "NPlateOrderVerify",
        5: "NPlateOrderShow",
        6: "NPlateOrderShow",
        7: "ScrapOrderVerify",
        8: "ScrapOrderShow",
        9: "ScrapOrderShow",
        13: "NPlateOrderEdit",
        14: "NPlateOrderVerify",
        15: "OrderEdit",
        16: "OrderVerify",
        17: "ScrapOrderEdit",
        18: "ScrapOrderVerify",
        30: "IssueCommentShow",
        31: "IssueCommentShow",
        32: "IssueCommentShow",
        33: "IssueCommentShow",
      };
      return id in dataRouting ? dataRouting[id] : "none";
    },
    scroll() {
      let bottomOfWindow = this.$refs.wrap.offsetHeight + this.$refs.wrap.scrollTop === this.$refs.list.offsetHeight;
      if (bottomOfWindow) {
        this.$parent._reloadNotifications(this.nextPage);
      }
    },
    readNotif(note) {
      this.$parent.showFeed = false;
      uspAPI
        .post("/api/notifications/mark-as-read/" + note.id + "/", null, {
          headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
        })
        .then((response) => {
          this.$parent._reloadNotifications(1, true);
          this.$parent.checkNotification();
          this.$router.push({
            name: this.orderRoute(note.notification_type),
            params: { id: note.context.order_pk || note.context.issue_pk },
          });
          return response;
        })
        .catch((err) => {
          if (err.response.status == 403) {
            this.$store.dispatch("setConfirmMessage", false);
            this.$router.push({ name: "Error403", params: { id: "NOTIFICATIONS_MARK_AS_READ" } });
          }
        });
    },
    markAllAsRead() {
      // this.$parent.showFeed = false
      uspAPI
        .post("/api/notifications/all-read/", null, {
          headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
        })
        .then((response) => {
          this.$parent._reloadNotifications(1, true);
          this.$parent.checkNotification();
          return response;
        })
        .catch((err) => {
          if (err.response.status == 403) {
            this.$store.dispatch("setConfirmMessage", false);
            this.$router.push({ name: "Error403", params: { id: "NOTIFICATIONS_MARK_AS_READ" } });
          }
        });
    },
    createdDate(date) {
      var part1 = dayjs(date).locale(this.$i18n.locale).format("lll");
      var part2 = dayjs(date).locale(this.$i18n.locale).fromNow();
      return part1 + " (" + part2 + ")";
    },
    statusName,
    statusIssueName,
  },
};
</script>
